// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link-display-container {
    display: flex;
    height: 40px;
    text-align: center;
    font-family: var(--paragraphFont);
    font-size: 20px;
    font-weight: 400;
    box-sizing: border-box;
    background-color: #8884;
    max-width: 100%;
}
.link-display-textbox {
    font-family: var(--paragraphFont);
    font-size: 16px;
    font-weight: 400;
    padding: 0 10px;
    border: none;
    max-width: 100%;
    color: white;
    transition: all 0.3s ease 0s;
    overflow-x: auto;
}
.link-display-text::-webkit-scrollbar {
    display: none;
}
.link-display-textbox:focus {
    outline: none;
}
.link-display-text {
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
    height: 100%;
    display: flex;
    align-items: center;
}

.link-display-copy-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    font-family: var(--paragraphFont);
    font-size: 20px;
    font-weight: 400;
    padding: 0;
    height: 100%;
    aspect-ratio: 1/1;
    border: none;
    color: white;
    transition: all 0.3s ease 0s;
    cursor: pointer;
}
.link-display-copy-icon {
    height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/styles/general/LinkDisplay.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,iCAAiC;IACjC,eAAe;IACf,gBAAgB;IAChB,sBAAsB;IACtB,uBAAuB;IACvB,eAAe;AACnB;AACA;IACI,iCAAiC;IACjC,eAAe;IACf,gBAAgB;IAChB,eAAe;IACf,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,4BAA4B;IAC5B,gBAAgB;AACpB;AACA;IACI,aAAa;AACjB;AACA;IACI,aAAa;AACjB;AACA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,6BAA6B;IAC7B,iCAAiC;IACjC,eAAe;IACf,gBAAgB;IAChB,UAAU;IACV,YAAY;IACZ,iBAAiB;IACjB,YAAY;IACZ,YAAY;IACZ,4BAA4B;IAC5B,eAAe;AACnB;AACA;IACI,YAAY;AAChB","sourcesContent":[".link-display-container {\n    display: flex;\n    height: 40px;\n    text-align: center;\n    font-family: var(--paragraphFont);\n    font-size: 20px;\n    font-weight: 400;\n    box-sizing: border-box;\n    background-color: #8884;\n    max-width: 100%;\n}\n.link-display-textbox {\n    font-family: var(--paragraphFont);\n    font-size: 16px;\n    font-weight: 400;\n    padding: 0 10px;\n    border: none;\n    max-width: 100%;\n    color: white;\n    transition: all 0.3s ease 0s;\n    overflow-x: auto;\n}\n.link-display-text::-webkit-scrollbar {\n    display: none;\n}\n.link-display-textbox:focus {\n    outline: none;\n}\n.link-display-text {\n    white-space: nowrap;\n    overflow-y: hidden;\n    overflow-x: auto;\n    height: 100%;\n    display: flex;\n    align-items: center;\n}\n\n.link-display-copy-button {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: transparent;\n    font-family: var(--paragraphFont);\n    font-size: 20px;\n    font-weight: 400;\n    padding: 0;\n    height: 100%;\n    aspect-ratio: 1/1;\n    border: none;\n    color: white;\n    transition: all 0.3s ease 0s;\n    cursor: pointer;\n}\n.link-display-copy-icon {\n    height: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
