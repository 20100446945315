// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contribute-agreements-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    justify-content: center;
    box-sizing: border-box;
    width:100%;
    font-size: 18px;
}

.main-agreements {
    width:100%;
    max-width: 100%;
    display:flex;
    flex-direction: column;
    row-gap: 10px;
}

.agreement-container {
    width:100%;
    box-sizing: border-box;
}

.agreement-container a {
    color: black;
    margin:0;
    padding:0;
}`, "",{"version":3,"sources":["webpack://./src/styles/contribute/ContributeAgreements.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;IACT,uBAAuB;IACvB,sBAAsB;IACtB,UAAU;IACV,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,eAAe;IACf,YAAY;IACZ,sBAAsB;IACtB,aAAa;AACjB;;AAEA;IACI,UAAU;IACV,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,QAAQ;IACR,SAAS;AACb","sourcesContent":[".contribute-agreements-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 20px;\n    justify-content: center;\n    box-sizing: border-box;\n    width:100%;\n    font-size: 18px;\n}\n\n.main-agreements {\n    width:100%;\n    max-width: 100%;\n    display:flex;\n    flex-direction: column;\n    row-gap: 10px;\n}\n\n.agreement-container {\n    width:100%;\n    box-sizing: border-box;\n}\n\n.agreement-container a {\n    color: black;\n    margin:0;\n    padding:0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
