// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-cropper-popup-backdrop {
	position: fixed;
	height: calc(100vh - var(--menuBarHeight));
	width: 100%;
	background-color: rgba(0, 0, 0, 0.392);
	-webkit-backdrop-filter: blur(5px);
	        backdrop-filter: blur(5px);
	z-index: 1000;
	box-sizing: border-box;
	overflow: auto;
}

.ic-popup-body-container {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
	padding: 20px;
	box-sizing: border-box;
	overflow: visible;
}

@keyframes grow {
    0% {
        transform: scale(0.5);
        opacity: 0.5;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.ic-popup-body {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    animation: grow 0.1s;
	position: relative;
	height: -moz-fit-content;
	height: fit-content;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.crop-supercontainer {
	position: relative;
	width: 400px;
	height: 400px;
}
.crop-container {
	position: relative;
	height: 100%;
	width: 100%;
}

/* This is an input type = range */
.zoom-range {
	width: 100%;
}
.zoom-range::-webkit-slider-thumb {
	cursor: pointer;
	background-color: var(--primaryColorBright);
}`, "",{"version":3,"sources":["webpack://./src/styles/general/ImageCropper.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,0CAA0C;CAC1C,WAAW;CACX,sCAAsC;CACtC,kCAA0B;SAA1B,0BAA0B;CAC1B,aAAa;CACb,sBAAsB;CACtB,cAAc;AACf;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;CACzB,aAAa;CACb,sBAAsB;CACtB,iBAAiB;AAClB;;AAEA;IACI;QACI,qBAAqB;QACrB,YAAY;IAChB;IACA;QACI,mBAAmB;QACnB,UAAU;IACd;AACJ;;AAEA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,oBAAoB;CACvB,kBAAkB;CAClB,wBAAmB;CAAnB,mBAAmB;CACnB,aAAa;CACb,sBAAsB;CACtB,SAAS;AACV;;AAEA;CACC,kBAAkB;CAClB,YAAY;CACZ,aAAa;AACd;AACA;CACC,kBAAkB;CAClB,YAAY;CACZ,WAAW;AACZ;;AAEA,kCAAkC;AAClC;CACC,WAAW;AACZ;AACA;CACC,eAAe;CACf,2CAA2C;AAC5C","sourcesContent":[".image-cropper-popup-backdrop {\n\tposition: fixed;\n\theight: calc(100vh - var(--menuBarHeight));\n\twidth: 100%;\n\tbackground-color: rgba(0, 0, 0, 0.392);\n\tbackdrop-filter: blur(5px);\n\tz-index: 1000;\n\tbox-sizing: border-box;\n\toverflow: auto;\n}\n\n.ic-popup-body-container {\n    min-height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n\tpadding: 20px;\n\tbox-sizing: border-box;\n\toverflow: visible;\n}\n\n@keyframes grow {\n    0% {\n        transform: scale(0.5);\n        opacity: 0.5;\n    }\n    100% {\n        transform: scale(1);\n        opacity: 1;\n    }\n}\n\n.ic-popup-body {\n    background-color: white;\n    border-radius: 10px;\n    padding: 20px;\n    animation: grow 0.1s;\n\tposition: relative;\n\theight: fit-content;\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 10px;\n}\n\n.crop-supercontainer {\n\tposition: relative;\n\twidth: 400px;\n\theight: 400px;\n}\n.crop-container {\n\tposition: relative;\n\theight: 100%;\n\twidth: 100%;\n}\n\n/* This is an input type = range */\n.zoom-range {\n\twidth: 100%;\n}\n.zoom-range::-webkit-slider-thumb {\n\tcursor: pointer;\n\tbackground-color: var(--primaryColorBright);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
