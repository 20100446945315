// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.submission-thumbnail {
    position: absolute;
    overflow: visible;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    /*transition: 0.1s;*/
    z-index: 5;
    border: none;
    outline: none;
    background-color: white; /* no transparent photos */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/styles/explore/SubmissionThumbnail.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,sBAAsB;IACtB,2BAA2B;IAC3B,4BAA4B;IAC5B,eAAe;IACf,oBAAoB;IACpB,UAAU;IACV,YAAY;IACZ,aAAa;IACb,uBAAuB,EAAE,0BAA0B;IACnD,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":[".submission-thumbnail {\n    position: absolute;\n    overflow: visible;\n    background-size: cover;\n    background-position: center;\n    background-repeat: no-repeat;\n    cursor: pointer;\n    /*transition: 0.1s;*/\n    z-index: 5;\n    border: none;\n    outline: none;\n    background-color: white; /* no transparent photos */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
