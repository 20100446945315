// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-page-content {
    min-height: 100%;
    display: flex;
    padding: min(60px, 8%) min(40px, 5%);
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(225deg, rgb(228, 32, 215), rgb(15, 182, 238))
}

.login-page-content a {
    text-decoration: none;
    color: var(--primaryColorBright);
    box-sizing: border-box;
}

.login-form-container {
    padding: min(40px, 8%);
    background-color: var(--primaryColor);
    border-radius: 5px;
    background-color: white;
    width: min(400px, 100%);
    box-sizing: border-box;
    box-shadow: -3px 5px 20px 10px rgba(0, 0, 0, 0.2);
}

.login-extralink {
    width: -moz-fit-content;
    width: fit-content;
    text-decoration: none;
    color: var(--primaryColorBright);
    font-size: 14px;
}

.login-option-button {
    border: none;
    background-color: transparent;
    padding: 5px;
    cursor: pointer;
}

.input-bottom-text {
    text-align: right;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}
.--too-weak {
    color: red;
}
.--weak {
    color: rgb(221, 118, 0);
}
.--medium {
    color: rgb(200, 181, 2);
}
.--strong {
    color: rgb(7, 183, 7);
}`, "",{"version":3,"sources":["webpack://./src/styles/account/PreAccount.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,oCAAoC;IACpC,sBAAsB;IACtB,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB;AACJ;;AAEA;IACI,qBAAqB;IACrB,gCAAgC;IAChC,sBAAsB;AAC1B;;AAEA;IACI,sBAAsB;IACtB,qCAAqC;IACrC,kBAAkB;IAClB,uBAAuB;IACvB,uBAAuB;IACvB,sBAAsB;IACtB,iDAAiD;AACrD;;AAEA;IACI,uBAAkB;IAAlB,kBAAkB;IAClB,qBAAqB;IACrB,gCAAgC;IAChC,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,6BAA6B;IAC7B,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,gBAAgB;IAChB,SAAS;AACb;AACA;IACI,UAAU;AACd;AACA;IACI,uBAAuB;AAC3B;AACA;IACI,uBAAuB;AAC3B;AACA;IACI,qBAAqB;AACzB","sourcesContent":[".login-page-content {\n    min-height: 100%;\n    display: flex;\n    padding: min(60px, 8%) min(40px, 5%);\n    box-sizing: border-box;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    background-image: linear-gradient(225deg, rgb(228, 32, 215), rgb(15, 182, 238))\n}\n\n.login-page-content a {\n    text-decoration: none;\n    color: var(--primaryColorBright);\n    box-sizing: border-box;\n}\n\n.login-form-container {\n    padding: min(40px, 8%);\n    background-color: var(--primaryColor);\n    border-radius: 5px;\n    background-color: white;\n    width: min(400px, 100%);\n    box-sizing: border-box;\n    box-shadow: -3px 5px 20px 10px rgba(0, 0, 0, 0.2);\n}\n\n.login-extralink {\n    width: fit-content;\n    text-decoration: none;\n    color: var(--primaryColorBright);\n    font-size: 14px;\n}\n\n.login-option-button {\n    border: none;\n    background-color: transparent;\n    padding: 5px;\n    cursor: pointer;\n}\n\n.input-bottom-text {\n    text-align: right;\n    font-size: 14px;\n    font-weight: 600;\n    margin: 0;\n}\n.--too-weak {\n    color: red;\n}\n.--weak {\n    color: rgb(221, 118, 0);\n}\n.--medium {\n    color: rgb(200, 181, 2);\n}\n.--strong {\n    color: rgb(7, 183, 7);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
