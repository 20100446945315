// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch-option {
    display: flex;
    font-size:16px;
    font-weight: 500;
    padding: 20px 25px;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
    -moz-user-select: none;
}
.switch-option-image {
    height: 40px;
    margin: auto 0;
}

.--opt-deselected {
    background-color: white;
    border: 2px solid #00000020;
    border-radius: 10px;
}
.--opt-deselected:hover {
    background-color: #0000000b;
}

.--opt-selected {
    border: 2px solid lime;
    background-color: #2eff274d;
    border-radius: 10px;
}
.--opt-selected:hover {
    background-color: #2eff2742;
}`, "",{"version":3,"sources":["webpack://./src/styles/general/SwitchOption.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;IACd,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;IACf,yBAAiB;YAAjB,iBAAiB;IACjB,sBAAsB;AAC1B;AACA;IACI,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,uBAAuB;IACvB,2BAA2B;IAC3B,mBAAmB;AACvB;AACA;IACI,2BAA2B;AAC/B;;AAEA;IACI,sBAAsB;IACtB,2BAA2B;IAC3B,mBAAmB;AACvB;AACA;IACI,2BAA2B;AAC/B","sourcesContent":[".switch-option {\n    display: flex;\n    font-size:16px;\n    font-weight: 500;\n    padding: 20px 25px;\n    cursor: pointer;\n    user-select: none;\n    -moz-user-select: none;\n}\n.switch-option-image {\n    height: 40px;\n    margin: auto 0;\n}\n\n.--opt-deselected {\n    background-color: white;\n    border: 2px solid #00000020;\n    border-radius: 10px;\n}\n.--opt-deselected:hover {\n    background-color: #0000000b;\n}\n\n.--opt-selected {\n    border: 2px solid lime;\n    background-color: #2eff274d;\n    border-radius: 10px;\n}\n.--opt-selected:hover {\n    background-color: #2eff2742;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
