// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faq-dropdown {
    width: 100%;
    border-radius: 0.5rem;
    border: 1px solid #0001;
    box-shadow: -6px 6px 12px 0px #0002;
    overflow: hidden;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
    padding: 10px 20px;
    cursor: pointer;
}

.faq-dropdown img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    padding: 2px;
}
.faq-dropdown img:hover {
    cursor: pointer;
    background-color: #00000020;
}

.faq-dropdown p {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    animation: fadein 0.3s ease-in-out;
}

.faq-question {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
    font-family: var(--displayFont);
}
.faq-question span {
    font-size: 20px;
    font-weight: 700;
    font-family: var(--displayFont);
}

.faq-answer {
    margin: 0;
    padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/styles/about/FaqDropdown.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,qBAAqB;IACrB,uBAAuB;IACvB,mCAAmC;IACnC,gBAAgB;IAChB,sBAAsB;IACtB,gCAAgC;IAChC,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,YAAY;AAChB;AACA;IACI,eAAe;IACf,2BAA2B;AAC/B;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,SAAS;IACT,UAAU;IACV,kCAAkC;AACtC;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,SAAS;IACT,UAAU;IACV,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,gBAAgB;IAChB,+BAA+B;AACnC;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,+BAA+B;AACnC;;AAEA;IACI,SAAS;IACT,UAAU;AACd","sourcesContent":[".faq-dropdown {\n    width: 100%;\n    border-radius: 0.5rem;\n    border: 1px solid #0001;\n    box-shadow: -6px 6px 12px 0px #0002;\n    overflow: hidden;\n    box-sizing: border-box;\n    transition: all 0.3s ease-in-out;\n    padding: 10px 20px;\n    cursor: pointer;\n}\n\n.faq-dropdown img {\n    height: 30px;\n    width: 30px;\n    border-radius: 50%;\n    padding: 2px;\n}\n.faq-dropdown img:hover {\n    cursor: pointer;\n    background-color: #00000020;\n}\n\n.faq-dropdown p {\n    font-size: 16px;\n    font-weight: 400;\n    margin: 0;\n    padding: 0;\n    animation: fadein 0.3s ease-in-out;\n}\n\n.faq-question {\n    font-size: 20px;\n    font-weight: 600;\n    margin: 0;\n    padding: 0;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    column-gap: 20px;\n    font-family: var(--displayFont);\n}\n.faq-question span {\n    font-size: 20px;\n    font-weight: 700;\n    font-family: var(--displayFont);\n}\n\n.faq-answer {\n    margin: 0;\n    padding: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
