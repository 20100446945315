// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.compact-profile-container {
    display: flex;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px;
    gap: 10px;
    align-items: center;
}

.compact-profile-displayName {
    font-weight: 700;
    line-height: 1.1;
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.compact-profile-username {
    font-weight: 500;
    line-height: 1.1;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./src/styles/general/CompactProfile.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,sBAAsB;IACtB,aAAa;IACb,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,eAAe;IACf,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB;AAC3B;AACA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,eAAe;IACf,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB;AAC3B","sourcesContent":[".compact-profile-container {\n    display: flex;\n    border-radius: 5px;\n    box-sizing: border-box;\n    padding: 10px;\n    gap: 10px;\n    align-items: center;\n}\n\n.compact-profile-displayName {\n    font-weight: 700;\n    line-height: 1.1;\n    font-size: 20px;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n.compact-profile-username {\n    font-weight: 500;\n    line-height: 1.1;\n    font-size: 14px;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
