// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inline-tag-container {
    border-radius: 1000px;
    padding: 0.1em 0.5em;
    border-style: solid;
    box-sizing: border-box;
    display: inline-block;
    width: -moz-fit-content;
    width: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: auto 0;
    pointer-events: none;
    -webkit-user-select: none;
            user-select: none;
}`, "",{"version":3,"sources":["webpack://./src/styles/general/InlineTag.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,oBAAoB;IACpB,mBAAmB;IACnB,sBAAsB;IACtB,qBAAqB;IACrB,uBAAkB;IAAlB,kBAAkB;IAClB,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;IACd,oBAAoB;IACpB,yBAAiB;YAAjB,iBAAiB;AACrB","sourcesContent":[".inline-tag-container {\n    border-radius: 1000px;\n    padding: 0.1em 0.5em;\n    border-style: solid;\n    box-sizing: border-box;\n    display: inline-block;\n    width: fit-content;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    margin: auto 0;\n    pointer-events: none;\n    user-select: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
