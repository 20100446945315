// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider {
    width: 100%;
    box-sizing: border-box;
    display:flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    gap: 10px;
}

.slider-item {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor:pointer;
    transition: 0.2s;
    font-size: 18px;
    padding: 15px 0;
    font-weight: 600;
}
.slider-subtext {
    display: flex;
    align-items: center;
    gap: 2px;
    font-size: 12px;
    font-weight: 400;
}

@media screen and (max-width: 600px) {
    .slider {
        gap: 5px !important;
    }
    .slider-item {
        font-size: 14px !important;
    }
    .slider-subtext {
        font-size: 10px !important;
    }

}
`, "",{"version":3,"sources":["webpack://./src/styles/general/Slider.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,sBAAsB;IACtB,YAAY;IACZ,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe;IACf,SAAS;AACb;;AAEA;IACI,sBAAsB;IACtB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;IACR,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI;QACI,mBAAmB;IACvB;IACA;QACI,0BAA0B;IAC9B;IACA;QACI,0BAA0B;IAC9B;;AAEJ","sourcesContent":[".slider {\n    width: 100%;\n    box-sizing: border-box;\n    display:flex;\n    justify-content: space-between;\n    align-items: center;\n    font-size: 18px;\n    gap: 10px;\n}\n\n.slider-item {\n    box-sizing: border-box;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    cursor:pointer;\n    transition: 0.2s;\n    font-size: 18px;\n    padding: 15px 0;\n    font-weight: 600;\n}\n.slider-subtext {\n    display: flex;\n    align-items: center;\n    gap: 2px;\n    font-size: 12px;\n    font-weight: 400;\n}\n\n@media screen and (max-width: 600px) {\n    .slider {\n        gap: 5px !important;\n    }\n    .slider-item {\n        font-size: 14px !important;\n    }\n    .slider-subtext {\n        font-size: 10px !important;\n    }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
