// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/Page404_bg.jpeg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._404-body h1 {
    font-family: var(--displayFont);
    font-size: 100px;
}

._404-body h2 {
    font-family: var(--displayFont);
    font-size: 40px;
}

._404-body p {
    font-family: var(--paragraphFont);
    font-weight: 600;
}

._404-body-container {
    display: flex;
    flex-direction: column;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-position: 100% 50%;
    background-size: cover;
    min-height: 100%;
    flex-grow: 1;
    padding: 10px 20px;
    gap: 10px;
    text-align: center;
    box-sizing: border-box;
}

._404-body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    height: -moz-fit-content;
    height: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/styles/general/_404Page.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,gBAAgB;AACpB;;AAEA;IACI,+BAA+B;IAC/B,eAAe;AACnB;;AAEA;IACI,iCAAiC;IACjC,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,yDAAqD;IACrD,6BAA6B;IAC7B,sBAAsB;IACtB,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,SAAS;IACT,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,wBAAmB;IAAnB,mBAAmB;AACvB","sourcesContent":["._404-body h1 {\n    font-family: var(--displayFont);\n    font-size: 100px;\n}\n\n._404-body h2 {\n    font-family: var(--displayFont);\n    font-size: 40px;\n}\n\n._404-body p {\n    font-family: var(--paragraphFont);\n    font-weight: 600;\n}\n\n._404-body-container {\n    display: flex;\n    flex-direction: column;\n    background-image: url('../../assets/Page404_bg.jpeg');\n    background-position: 100% 50%;\n    background-size: cover;\n    min-height: 100%;\n    flex-grow: 1;\n    padding: 10px 20px;\n    gap: 10px;\n    text-align: center;\n    box-sizing: border-box;\n}\n\n._404-body {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    align-items: center;\n    justify-content: center;\n    flex-grow: 1;\n    height: fit-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
