// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.locsel-container {
    height: 100%;
    width: 100%;
    background-color: rgb(16, 16, 17);
}

.grid-overlay {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}

.locsel-thumbnails-container {
    position: relative;
}

.locsel-pan-content {
    padding: 50px !important;
}
.locsel-pan-wrapper {
    width: 100%;
    height: 100%;
    background-color: rgb(24, 24, 26);
}`, "",{"version":3,"sources":["webpack://./src/styles/contribute/ContributeLocationSelector.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,iCAAiC;AACrC;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,wBAAwB;AAC5B;AACA;IACI,WAAW;IACX,YAAY;IACZ,iCAAiC;AACrC","sourcesContent":[".locsel-container {\n    height: 100%;\n    width: 100%;\n    background-color: rgb(16, 16, 17);\n}\n\n.grid-overlay {\n    position: absolute;\n    top: 0;\n    left: 0;\n    cursor: pointer;\n}\n\n.locsel-thumbnails-container {\n    position: relative;\n}\n\n.locsel-pan-content {\n    padding: 50px !important;\n}\n.locsel-pan-wrapper {\n    width: 100%;\n    height: 100%;\n    background-color: rgb(24, 24, 26);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
