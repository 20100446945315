// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-history-item {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border: 1px solid #0002;
    box-shadow: -4px 4px 12px 0px #0002;
    border-radius: 8px;
}

.phi-image {
    height: 80px;
    border-radius: 8px;
    object-fit: cover;
    box-shadow: -1px 1px 10px 0px #0002;

}

/* IMPORTANT - this must match the value in PastPaymentItem.jsx */
@media screen and (max-width: 600px) {
    .payment-history-item {
        flex-direction: column;
        gap: 10px;
        align-items: unset;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/account/PastPaymentItem.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,sBAAsB;IACtB,kBAAkB;IAClB,8BAA8B;IAC9B,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,mCAAmC;IACnC,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,mCAAmC;;AAEvC;;AAEA,iEAAiE;AACjE;IACI;QACI,sBAAsB;QACtB,SAAS;QACT,kBAAkB;IACtB;AACJ","sourcesContent":[".payment-history-item {\n    display: flex;\n    width: 100%;\n    box-sizing: border-box;\n    text-align: center;\n    justify-content: space-between;\n    align-items: center;\n    padding: 15px;\n    border: 1px solid #0002;\n    box-shadow: -4px 4px 12px 0px #0002;\n    border-radius: 8px;\n}\n\n.phi-image {\n    height: 80px;\n    border-radius: 8px;\n    object-fit: cover;\n    box-shadow: -1px 1px 10px 0px #0002;\n\n}\n\n/* IMPORTANT - this must match the value in PastPaymentItem.jsx */\n@media screen and (max-width: 600px) {\n    .payment-history-item {\n        flex-direction: column;\n        gap: 10px;\n        align-items: unset;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
