// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --ec-scaledbutton: min(30px, 3vmax);
    --ec-scaledbuttonLess: min(20px, 2vmax);
}

.explore-controls-container {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 4;
    padding: 0 0 var(--ec-scaledbuttonLess) var(--ec-scaledbuttonLess);
    -webkit-user-select: none;
            user-select: none;
    display: flex;
    flex-direction: column;
    gap: calc(var(--ec-scaledbutton) * 0.5);
}

.ec-button {
    border-radius: 1000px;
    height: -moz-fit-content;
    height: fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0;
    padding: calc(var(--ec-scaledbutton) * 0.4);
    background-color: #0004;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: box-shadow 0.2s;
}
.ec-button:hover {
    background-color: rgba(40, 56, 98, 0.533);
    box-shadow: 0 0 0 calc(var(--ec-scaledbutton) * 0.1) var(--primaryColorTransparent);
}
.ec-btn-disabled {
    cursor: default;
    opacity: 0.2;
}
.ec-btn-disabled:hover {
    background-color: #0004 !important;
    box-shadow: none !important;
}

.ec-btn-icon {
    filter: invert(1);
    width: var(--ec-scaledbutton);
}

.ec-info {
    font-size: calc(var(--ec-scaledbuttonLess));
    color: #8888;
}`, "",{"version":3,"sources":["webpack://./src/styles/explore/ExploreControls.css"],"names":[],"mappings":"AAAA;IACI,mCAAmC;IACnC,uCAAuC;AAC3C;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,UAAU;IACV,kEAAkE;IAClE,yBAAiB;YAAjB,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,uCAAuC;AAC3C;;AAEA;IACI,qBAAqB;IACrB,wBAAmB;IAAnB,mBAAmB;IACnB,uBAAkB;IAAlB,kBAAkB;IAClB,SAAS;IACT,2CAA2C;IAC3C,uBAAuB;IACvB,YAAY;IACZ,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,2BAA2B;AAC/B;AACA;IACI,yCAAyC;IACzC,mFAAmF;AACvF;AACA;IACI,eAAe;IACf,YAAY;AAChB;AACA;IACI,kCAAkC;IAClC,2BAA2B;AAC/B;;AAEA;IACI,iBAAiB;IACjB,6BAA6B;AACjC;;AAEA;IACI,2CAA2C;IAC3C,YAAY;AAChB","sourcesContent":[":root {\n    --ec-scaledbutton: min(30px, 3vmax);\n    --ec-scaledbuttonLess: min(20px, 2vmax);\n}\n\n.explore-controls-container {\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    z-index: 4;\n    padding: 0 0 var(--ec-scaledbuttonLess) var(--ec-scaledbuttonLess);\n    user-select: none;\n    display: flex;\n    flex-direction: column;\n    gap: calc(var(--ec-scaledbutton) * 0.5);\n}\n\n.ec-button {\n    border-radius: 1000px;\n    height: fit-content;\n    width: fit-content;\n    margin: 0;\n    padding: calc(var(--ec-scaledbutton) * 0.4);\n    background-color: #0004;\n    border: none;\n    cursor: pointer;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    transition: box-shadow 0.2s;\n}\n.ec-button:hover {\n    background-color: rgba(40, 56, 98, 0.533);\n    box-shadow: 0 0 0 calc(var(--ec-scaledbutton) * 0.1) var(--primaryColorTransparent);\n}\n.ec-btn-disabled {\n    cursor: default;\n    opacity: 0.2;\n}\n.ec-btn-disabled:hover {\n    background-color: #0004 !important;\n    box-shadow: none !important;\n}\n\n.ec-btn-icon {\n    filter: invert(1);\n    width: var(--ec-scaledbutton);\n}\n\n.ec-info {\n    font-size: calc(var(--ec-scaledbuttonLess));\n    color: #8888;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
